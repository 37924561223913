import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Layout = {
  i: string; // Unique identifier of the item
  x: number; // X position in grid units
  y: number; // Y position in grid units
  w: number; // Width in grid units
  h: number; // Height in grid units
  minW: number;  // min width in grid units
  minH: number; // min height in grid units
};

const AccessDenied: React.FC = () => {

  const layout: Layout[] = [
    { i: 'a', x: 2, y: 3, w: 6, h: 6, minW: 1, minH: 6 },
    { i: 'b', x: 1, y: 7, w: 7, h: 5, minW: 1, minH: 2 },
  ];

  return (
    <ResponsiveGridLayout 
      className="layout" 
      layouts={{ lg: layout }} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 3, md: 3, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
      >
      <div key="a" style={{ minHeight: "500px" }} className='card-gauge'>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
          <h1>OOPS!</h1>
          <h3>Looks like you don't have access to this page.</h3>
          <p>Please contact us to remedy the situation, or kindly navigate to a different page in our app.</p>
        </div>
      </div>
      <div key="b" style={{ minHeight: "500px" }} className='card-gauge'>
      </div>
    </ResponsiveGridLayout>
  );
};

export default AccessDenied;