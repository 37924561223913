import * as React from 'react';
import { Account, AccountPopoverFooter, AccountPreview, SignOutButton } from '@toolpad/core/Account';
import { type SidebarFooterProps } from '@toolpad/core/DashboardLayout';
import { useNavigate } from 'react-router-dom';
import { MenuItem, MenuList, Divider, Stack } from '@mui/material';

export default function SidebarFooterAccount({ mini }: SidebarFooterProps) {
  const navigate = useNavigate();

  return (
    <Account
      slots={{
        preview: (props) => <AccountPreview {...props} variant={mini ? 'condensed' : 'expanded'} />,
        popoverContent: () => (
          <Stack direction="column">
            <Divider />
            <MenuList>
              <MenuItem onClick={() => navigate('/account-settings')}>Account Settings</MenuItem>
            </MenuList>
            <Divider />
            <AccountPopoverFooter>
              <SignOutButton />
            </AccountPopoverFooter>
          </Stack>
        ),
      }}
      slotProps={{
        popover: {
          transformOrigin: { horizontal: 'left', vertical: 'bottom' },
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          disableAutoFocus: true,
          slotProps: {
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: (theme) =>
                  `drop-shadow(0px 2px 8px ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.10)' : 'rgba(0,0,0,0.32)'})`,
                mt: 1
              },
            },
          },
        },
      }}
    />
  );
}