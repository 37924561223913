import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout'
import { Divider, Card, Typography, CardContent, Box } from '@mui/material';
import TempChart from '../charts-graphs/tempChart';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Layout = {
  i: string; // Unique identifier of the item
  x: number; // X position in grid units
  y: number; // Y position in grid units
  w: number; // Width in grid units
  h: number; // Height in grid units
  minW: number;  // min width in grid units
  minH: number; // min height in grid units
};

const EnvironGrid: React.FC = () => {

  const layout: Layout[] = [
    { i: 'a', x: 0, y: 0, w: 2, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'b', x: 0, y: 2, w: 2, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'c', x: 0, y: 4, w: 2, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'd', x: 0, y: 6, w: 2, h: 1.5, minW: 1, minH: 1.5 },
    { i: 'e', x: 0, y: 7, w: 7, h: 1, minW: 1, minH: 1 },
    { i: 'f', x: 2, y: 0, w: 7, h: 6, minW: 6, minH: 6 },
  ];

  return (
    <ResponsiveGridLayout 
      className="layout" 
      layouts={{ lg: layout }} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 9, md: 6, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
      >
      <div key="a" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'success.main' }}>70</Typography>
              <Typography variant="body2" sx={{ color: 'success.main', ml: 0.5 }}>°F</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Temperature
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'success.main' }} />
          </CardContent>
        </Card>
      </div>
      <div key="b" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'warning.main' }}>60</Typography>
              <Typography variant="body2" sx={{ color: 'warning.main', ml: 0.5 }}>%</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Humidity
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'warning.main' }} />
          </CardContent>
        </Card>
      </div>
      <div key="c" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '4px' }}>
              <Typography variant="h5" sx={{ color: 'secondary.main' }}>52</Typography>
              <Typography variant="body2" sx={{ color: 'secondary.main', ml: 0.5 }}>PM2.5</Typography>
            </Box>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
              <Typography variant="h5" sx={{ color: 'secondary.main' }}>38</Typography>
              <Typography variant="body2" sx={{ color: 'secondary.main', ml: 0.5 }}>O3</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '4px' }}>
              AQI
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'secondary.main' }} />
          </CardContent>
        </Card>
      </div>
      <div key="d" style={{ minHeight: "150px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
          <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box sx={{ textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'baseline', mt: '10px' }}>
              <Typography variant="h3" sx={{ color: 'info.main' }}>7</Typography>
              <Typography variant="body2" sx={{ color: 'info.main', ml: 0.5 }}>mph</Typography>
            </Box>
            <br />
            {/* <Divider /> */}
            <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary', textAlign: 'center', mt: '5px' }}>
              Windspeed
            </Typography>
            <Divider sx={{ borderBottomWidth: '3px', borderColor: 'info.main' }} />
          </CardContent>
        </Card>
      </div>
      <div key="e">
        <Divider textAlign="left">
          <Typography variant="body2" sx={{ color: 'text.primary', ml: 0.5 }}>Anything can have gauges/charts in addition to text, in any colors/order/size we want. --Cards can be in any order as well.</Typography>
        </Divider>
      </div>
      <div key="f" style={{ minHeight: "500px" }} className='card-gauge'>
        <Card sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
            <TempChart/>
        </Card>
      </div>
    </ResponsiveGridLayout>
  );
};

export default EnvironGrid;