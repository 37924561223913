import { atom } from 'jotai'
import LatLng from '../defs/latLng-def';
import ZoomDef from '../defs/zoom-def';

export const mapCenterAtom = atom<LatLng>({
  lat: 42.82941,
  lng: -71.60524
})

export const mapZoomAtom = atom<ZoomDef>({
  zoom: 12
})