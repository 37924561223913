import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useAtom } from 'jotai';
import { buildingAtom } from '../atoms/buildingAtom';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

const BuildingGraph = () => {
  const [buildingData] = useAtom(buildingAtom);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480);
      const chartElement = document.querySelector('.echarts-for-react');
      if (chartElement) {
        const echartsInstance = (chartElement as any).__ECharts__;
        if (echartsInstance) {
          echartsInstance.resize();
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!buildingData) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
      }}>
        <CircularProgress style={{ color: 'firebrick' }} />
        <h3 style={{ color: 'text.primary', marginTop: '20px' }}>Waiting for data...</h3>
      </div>
    );
  }

  const xAxisData = buildingData.map(b => {
    const formattedTime = moment.utc(b.Timestamp).tz('America/New_York').format('HH:mm:ss');
    console.log(`Raw timestamp: ${b.Timestamp}, Formatted (EST): ${formattedTime}`);
    return formattedTime;
  });


  const Charging: number[] = buildingData.map(b => b.NCharging ?? 0);
  const Waiting: number[] = buildingData.map(b => b.NWaiting ?? 0);
  const Grid: number[] = buildingData.map(b => b.Grid ?? 0);
  const Load: number[] = buildingData.map(b => b.Load ?? 0);
  const Solar: number[] = buildingData.map(b => b.Solar ?? 0);
  const Demand: number[] = buildingData.map(b => b.Demand ?? 0);
  const DayPeak: number[] = buildingData.map(b => b.DayPeak ?? 0);

  const option = {
    // baseOption: {
    //     timeline: {},
    //     series: [],
    // },
    backgroundColor: 'transparent',
    title: {
      show: !isMobile,
      text: 'Building: Data Over Time',
      textStyle: {
        color: 'grey',
      },
    },
    tooltip: { show: !isMobile },
    legend: {
      data: ['Charging', 'Waiting', 'Grid', 'Load', 'Solar', 'Demand', 'DayPeak'],
      textStyle: {
        color: 'grey',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxisData,
      axisLabel: {
        color: 'grey',
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: 'grey',
      },
    },
    series: [
      {
        name: 'Charging',
        type: 'line',
        data: Charging,
        lineStyle: {
          color: 'grey'
        },
        itemStyle: {
          color: 'grey'
        }
      },
      {
        name: 'Waiting',
        type: 'line',
        data: Waiting,
        lineStyle: {
          color: 'coral'
        },
        itemStyle: {
          color: 'coral'
        }
      },
      {
        name: 'Grid',
        type: 'line',
        data: Grid,
        lineStyle: {
          color: 'firebrick'
        },
        itemStyle: {
          color: 'firebrick'
        }
      },
      {
        name: 'Load',
        type: 'line',
        data: Load,
        lineStyle: {
          color: 'steelblue'
        },
        itemStyle: {
          color: 'steelblue'
        }
      },
      {
        name: 'Solar',
        type: 'line',
        data: Solar,
        lineStyle: {
          color: 'gold'
        },
        itemStyle: {
          color: 'gold'
        }
      },
      {
        name: 'Demand',
        type: 'line',
        data: Demand,
        lineStyle: {
          color: 'slateblue'
        },
        itemStyle: {
          color: 'slateblue'
        }
      },
      {
        name: 'DayPeak',
        type: 'line',
        data: DayPeak,
        lineStyle: {
          color: 'seagreen'
        },
        itemStyle: {
          color: 'seagreen'
        }
      }
    ],
  };

  return(
    <ReactECharts 
      option={option} 
      className="cancelSelectorName" 
      style={{ height: '100%', width: '100%' }} 
      opts={{ renderer: 'canvas' }}
    />
  )
};

export default BuildingGraph;





