import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Auth0Provider, AppState } from '@auth0/auth0-react';

const queryClient = new QueryClient();

const auth0Domain = process.env.AUTHO_DOMAIN;
const auth0ClientId = process.env.AUTHO_CLIENT_ID;
const auth0Audience = process.env.AUTHO_AUDIENCE;
const auth0Redirect = process.env.AUTHO_REDIRECT_URI || "https://odp.golf/"

const onRedirectCallback = (appState?: AppState) => {
  // Log the appState object to see its contents
  console.log('Redirect appState:', appState);

  // Logic to handle redirection after login
  window.location.href = appState?.returnTo || auth0Redirect;
};

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Auth0Provider
        domain={auth0Domain || "dev-u0u6agcpjzmqzm01.us.auth0.com"}
        clientId={auth0ClientId || "KvNtMjfo3ZgxNwIJuO0WnPBPdOKEjOgG"}
        authorizationParams={{ 
          audience: auth0Audience || "https://odp.golf/audience",
          redirect_uri: window.location.origin
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Auth0Provider>
    </React.StrictMode>
  );
} else {
  console.error("Root element not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

