import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import { chargingAtom } from '../atoms/chargingAtom';
import useWebSocket from '../wsHook';
import ChargingGraph from '../charts-graphs/chargingGraph'
import { Charger } from '../defs/charger-def';

const ChargingHandler = () => {
  const { messages } = useWebSocket('https://odp.golf/websocket', ['charger_update']);
  const setChargingData = useSetAtom(chargingAtom);

  const { isFetching, isError, error } = useQuery({
    queryKey: ['fallbackCharging'],
    staleTime: Infinity,
    queryFn: async () => {
      const response = await axios.get<Charger[]>('https://odp.golf/api/fallback-charging');
      const data = response.data;
      UpdateChargingData(data);
      console.log("all fallback Charging: ", data);
      return data;
    }
  });

  function UpdateChargingData(data: Charger[]) {
    console.log("UpdateChargingData:", data[0]);
    if (data.length === 0) {
      console.error('No data available to update charging information');
      return;
    }

    const chargingData = data[0];

    const parsedData = {
      NCharging: chargingData.NCharging,
      NWaiting: chargingData.NWaiting,
      PhA: chargingData.PhA,
      PhB: chargingData.PhB,
      PhC: chargingData.PhC,
      Grid: chargingData.Grid,
      Load: chargingData.Load,
      Solar: chargingData.Solar,
      Timestamp: chargingData.Timestamp,
      CState: typeof chargingData.CState === 'string' ? JSON.parse(chargingData.CState) : chargingData.CState,
      CPower: typeof chargingData.CPower === 'string' ? JSON.parse(chargingData.CPower) : chargingData.CPower,
      CEnergy: typeof chargingData.CEnergy === 'string' ? JSON.parse(chargingData.CEnergy) : chargingData.CEnergy
    };

    console.log('Parsed Data:', parsedData);
    setChargingData(parsedData);
  }


  useEffect(() => {
    messages.forEach((messageObj) => {
      const { channel, payload } = messageObj;

      if (channel === 'charger_update') {
        try {
          if (payload) {
            console.log('Updating charging data:', payload);
            setChargingData(payload as Charger);
          } else {
            console.error('Invalid charger payload format:', payload);
          }
        } catch (error) {
          console.error('Failed to parse message:', error);
        }
      }
    });
  }, [messages, setChargingData]);

  const renderLoadingOrError = () => {
    if (isFetching) {
      return <p>Loading...</p>;
    } else if (isError) {
      return <p>Error: {error.message}</p>;
    }
    return null;
  };

  return (
    <div>
      {/* {renderLoadingOrError()} */}
      <ChargingGraph />
    </div>
  );
};

export default ChargingHandler;


