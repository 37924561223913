import React from 'react';
import { Card, CardContent, Chip, Typography } from '@mui/material';
import { chargingAtom } from '../atoms/chargingAtom';
import { useAtom } from 'jotai';

export default function ChargingCard() {
  const [chargingData,] = useAtom(chargingAtom);

  return (
    <>
      {chargingData &&
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Utility kW:
            </Typography>
            <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Charging</Typography>
                <Chip 
                  label={`${chargingData.NCharging}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Waiting</Typography>
                <Chip 
                  label={`${chargingData.NWaiting}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>PhA</Typography>
                <Chip 
                  label={`${chargingData.PhA.toFixed(4)}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>PhB</Typography>
                <Chip 
                  label={`${chargingData.PhB.toFixed(4)}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>PhC</Typography>
                <Chip 
                  label={`${chargingData.PhC.toFixed(4)}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Load</Typography>
                <Chip 
                  label={`${chargingData.Load.toFixed(4)}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Solar</Typography>
                <Chip 
                  label={`${chargingData.Solar.toFixed(4)}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography>Grid</Typography>
                <Chip 
                  label={`${chargingData.Grid.toFixed(4)}`} 
                  sx={{ backgroundColor: 'steelblue', color: 'white' }} 
                />
              </div>
            </div>
          </CardContent>
        </Card>
      }
    </>
  );
};