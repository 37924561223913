import React from 'react';
import ReactECharts from 'echarts-for-react';

const TempChart: React.FC = () => {
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      }
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        magicType: { show: true, type: ['line', 'bar'] },
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    legend: {
      data: ['Evaporation', 'Precipitation', 'Temperature'],
      textStyle: {
        color: 'grey',
      },
    },
    xAxis: [
      {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        axisPointer: {
          type: 'shadow'
        },
        axisLabel: {
          color: 'text.secondary',
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Precipitation',
        min: 0,
        max: 250,
        interval: 50,
        axisLabel: {
          formatter: '{value} ml',
          color: 'text.secondary',
        }
      },
      {
        type: 'value',
        name: 'Temperature',
        min: 0,
        max: 25,
        interval: 5,
        axisLabel: {
          formatter: '{value} °C',
          color: 'text.secondary',
        }
      }
    ],
    series: [
      {
        name: 'Evaporation',
        type: 'bar',
        tooltip: {
          valueFormatter: function (value: number) {
            return value + ' ml';
          }
        },
        data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6],
        itemStyle: {
          color: 'aqua',
        }
      },
      {
        name: 'Precipitation',
        type: 'bar',
        tooltip: {
          valueFormatter: function (value: number) {
            return value + ' ml';
          }
        },
        data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6],
        itemStyle: {
          color: 'coral',
        }
      },
      {
        name: 'Temperature',
        type: 'line',
        yAxisIndex: 1,
        tooltip: {
          valueFormatter: function (value: number) {
            return value + ' °C';
          }
        },
        data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3],
        lineStyle: {
          color: 'gold',
        },
        itemStyle: {
          color: 'gold',
        }
      }
    ]
  };

  return (
    <ReactECharts 
      option={option} 
      className="cancelSelectorName" 
      style={{ height: '400px', width: '100%' }} 
      opts={{ renderer: 'canvas' }}
    />
  )
};

export default TempChart;
