import React, { useMemo } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DevicesGrid from './components/grids/devicesGrid';
import ChargingGrid from './components/grids/chargingGrid';
import ProtectedRoute from './components/protectedRoute';
import GolfMapHandler from './components/handlers/golfMap-handler';
import EnvironGrid from './components/grids/environmentalGrid';
import DashboardGrid from './components/grids/dashboardGrid';
import Toolbar from './components/navigation/toolbar';
import BuildingGrid from './components/grids/buildingGrid';
import { useAuth0 } from '@auth0/auth0-react';
import AccountSettingsGrid from './components/grids/accountSettingsGrid';

function App() {
  const { user } = useAuth0();

  // Memoize roles with a default fallback to 'Staff'
  const roles = useMemo(() => {
    const userRoles = user?.['https://odp.golf/roles'] || [];
    if (userRoles.length === 0) {
      console.log('No roles assigned, defaulting to Staff');
      return ['Staff'];
    }
    return userRoles;
  }, [user]);

  console.log('User roles:', roles);

  return (
    <Router>
      <Routes>
        <Route element={<Toolbar />}>
          {/* Root route */}
          <Route
            path="/"
            element={
              <ProtectedRoute allowedRoles={['Staff', 'Manager', 'Admin', 'Super']}>
                {roles.includes('Staff') ? <GolfMapHandler /> : <DashboardGrid />}
                {roles.includes('') ? <GolfMapHandler /> : <DashboardGrid />}
              </ProtectedRoute>
            }
          />
          {/* Other routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute allowedRoles={['Manager', 'Admin', 'Super']}>
                <DashboardGrid />
              </ProtectedRoute>
            }
          />
          <Route path="/map" element={<GolfMapHandler />} />
          <Route path="/location/map" element={<GolfMapHandler />} />
          <Route path="/:deviceID" element={<GolfMapHandler />} />
          <Route
            path="/location/trackers"
            element={
              <ProtectedRoute allowedRoles={['Manager', 'Admin', 'Super']}>
                <DevicesGrid />
              </ProtectedRoute>
            }
          />
          <Route path="/charging" element={<ChargingGrid />} />
          <Route
            path="/building"
            element={
              <ProtectedRoute allowedRoles={['Manager', 'Admin', 'Super']}>
                <BuildingGrid />
              </ProtectedRoute>
            }
          />
          <Route path="/environment" element={<EnvironGrid />} />
        </Route>
        <Route path="/account-settings" element={<AccountSettingsGrid />} />
      </Routes>
    </Router>
  );
}

export default App;


