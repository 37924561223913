import React from 'react';
import { WidthProvider, Responsive } from 'react-grid-layout';
import ChargingHandler from '../handlers/chargingGraph-handler';
import TogglePowerEnergyButton from '../buttons/toggleChargingGraph';
import ChargingCard from '../cards/charging-card';
import { Card } from '@mui/material';

const ResponsiveGridLayout = WidthProvider(Responsive);

const ChargingGrid: React.FC = () => {

  const layouts = {
    lg: [
      { i: 'card', x: 1, y: 0, w: 9, h: 1, minW: 6, minH: 2 },
      { i: 'graph', x: 1, y: 1, w: 9, h: 5, minW: 6, minH: 5 },
    ],
    md: [
      { i: 'card', x: 0, y: 0, w: 6, h: 2, minW: 4, minH: 2 },
      { i: 'graph', x: 0, y: 1, w: 6, h: 5, minW: 4, minH: 5 },
    ],
    sm: [
      { i: 'card', x: 0, y: 0, w: 3, h: 2, minW: 2, minH: 2 },
      { i: 'graph', x: 0, y: 1, w: 3, h: 4, minW: 2, minH: 4 },
    ],
    xs: [
      { i: 'card', x: 0, y: 0, w: 1, h: 2, minW: 1, minH: 2 },
      { i: 'graph', x: 0, y: 1, w: 1, h: 4, minW: 1, minH: 4 },
    ]
  };

  return (
    <ResponsiveGridLayout 
      className="layout" 
     layouts={layouts} 
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480 }} 
      cols={{ lg: 9, md: 6, sm: 3, xs: 1 }}
      rowHeight={100}
      draggableCancel=".cancelSelectorName"
      isDraggable={false}
      isResizable={false}
      >
      <div key="card" style={{ minHeight: "100px" }} className='card-gauge'>
        <ChargingCard/>
      </div>
      <div key="graph" className='card-gauge'>
        <Card style={{ minHeight: "500px", width: "100%" }}>
          <ChargingHandler />
        </Card>
        <br/>
        <TogglePowerEnergyButton />
      </div>
    </ResponsiveGridLayout>
  );
};

export default ChargingGrid;