import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import AccessDenied from './grids/accessDeniedGrid';
import { ROLE_HIERARCHY } from './utils/roleUtils';

type ProtectedRouteProps = {
  children: React.ReactNode;
  allowedRoles?: string[];
};

type DecodedToken = {
  [key: string]: any;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles = [] }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const checkAccess = async () => {
      if (!isLoading && isAuthenticated) {
        try {
          // Fetch access token
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: "https://odp.golf/audience",
              scope: "openid profile email",
            },
          });

          // Decode token to get user roles
          const decodedToken: DecodedToken = jwtDecode(token);
          const userRoles = decodedToken['https://odp.golf/roles'] || [];
          // console.log('User roles:', userRoles);

          // Expand allowed roles using ROLE_HIERARCHY
          const effectiveAllowedRoles = allowedRoles.flatMap((role) => ROLE_HIERARCHY[role] || []);
          // console.log('Allowed roles:', allowedRoles);
          // console.log('Effective allowed roles:', effectiveAllowedRoles);

          // Check if any user role matches the effective allowed roles
          const hasRole = userRoles.some((userRole: string) => effectiveAllowedRoles.includes(userRole));
          // console.log('Has valid role:', hasRole);

          setHasAccess(hasRole);
        } catch (error) {
          console.error('Error fetching roles:', error);
          setHasAccess(false);
        }
      }
    };

    checkAccess();
  }, [isAuthenticated, isLoading, getAccessTokenSilently, allowedRoles]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isLoading || !isAuthenticated) {
    console.log('Auth state: Loading or unauthenticated');
    return <div>Loading...</div>;
  }

  if (!hasAccess) {
    console.log('Access denied: User lacks necessary roles');
    return <AccessDenied />;
  }

  console.log('Access granted: Rendering child components');
  return <>{children}</>;
};

export default ProtectedRoute;



